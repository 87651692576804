.jobs-page {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .jobs-hero {
    background: linear-gradient(
        rgba(28, 95, 1, 0.7),
        rgba(28, 95, 1, 0.7)
      ),
      url('../images/handshake.webp')
        center/cover no-repeat;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
  }
  
  .jobs-hero h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .jobs-hero p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .job-listings {
    padding: 50px 20px;
    background: #f9f9f9;
    text-align: center;
  }
  
  .job-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .job-card {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: box-shadow 0.3s ease;
  }
  
  .job-card:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .job-card h3 {
    font-size: 1.5rem;
    color: #1c5f01;
    margin-bottom: 10px;
  }
  
  .job-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .apply-button {
    background: #1c5f01;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .apply-button:hover {
    background: #155b02;
  }
  
  .why-work-with-us {
    padding: 50px 20px;
    text-align: center;
    background: #e6f9e6;
  }
  
  .why-work-with-us h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .why-work-with-us ul {
    list-style-type: none;
    padding: 0;
  }
  
  .why-work-with-us li {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .how-to-apply {
    padding: 50px 20px;
    background: #f9f9f9;
    text-align: center;
  }
  
  .how-to-apply h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .how-to-apply p {
    font-size: 1.2rem;
    color: #666;
  }
  

  /* Medium devices (Tablets, max-width: 768px) */
@media (max-width: 768px) {
  .jobs-hero {
    padding: 50px 15px; /* Reduce padding for smaller screens */
  }

  .jobs-hero h1 {
    font-size: 2rem; /* Adjust heading size */
  }

  .jobs-hero p {
    font-size: 1rem; /* Compact subtext */
  }

  .job-grid {
    grid-template-columns: 1fr; /* Stack job cards vertically */
    gap: 15px;
  }

  .job-card {
    padding: 15px; /* Compact card padding */
  }

  .job-card h3 {
    font-size: 1.2rem; /* Reduce font size */
  }

  .job-card p {
    font-size: 0.9rem; /* Adjust text size */
  }

  .apply-button {
    font-size: 0.9rem; /* Smaller button text */
    padding: 8px 15px;
  }

  .why-work-with-us ul {
    text-align: left; /* Align list items for better readability */
    padding: 0 15px;
  }

  .why-work-with-us li {
    font-size: 1rem;
  }
}

/* Small devices (Phones, max-width: 480px) */
@media (max-width: 480px) {
  .jobs-hero {
    padding: 30px 10px; /* Further compact hero section */
  }

  .jobs-hero h1 {
    font-size: 1.8rem; /* Smaller hero text */
  }

  .jobs-hero p {
    font-size: 0.9rem; /* Compact paragraph text */
  }

  .job-card {
    padding: 10px; /* Further compact job cards */
    font-size: 0.8rem;
  }

  .apply-button {
    font-size: 0.8rem; /* Adjust button size */
    padding: 6px 10px;
  }

  .why-work-with-us h2, .how-to-apply h2 {
    font-size: 1.5rem; /* Reduce section heading size */
  }

  .why-work-with-us ul, .how-to-apply p {
    font-size: 0.9rem; /* Adjust text for readability */
    padding: 0 10px;
  }
}