.conference-page {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
  }
  
  /* Hero Section */
  .conference-hero {
    background: linear-gradient(
        rgba(28, 95, 1, 0.7),
        rgba(28, 95, 1, 0.7)
      ),
      url('../images/conference.jpg')
        center/cover no-repeat;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
  }
  
  .hero-content h1 {
    font-size: 2.8rem;
    color: #f7f0b5; /* Light gold for a premium feel */
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #e0d3a0; /* Softer gold for secondary text */
  }
  
  /* Layout Section */
  .conference-layout {
    padding: 50px 20px;
    background-color: #e8f5e9; /* Light green for subtle background variation */
    text-align: center;
  }
  
  .conference-layout h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #1c5f01;
  }
  
  .conference-layout p {
    margin-bottom: 20px;
    color: #4e4e4e;
  }
  
  .layout-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .layout-card {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .layout-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  .layout-card img {
    width: 100%;
    border-radius: 10px;
    height: 150px;
    object-fit: cover;
  }
  
  .layout-card h3 {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #1c5f01;
  }
  
  /* Highlights Section */
  .conference-highlights {
    padding: 50px 20px;
    background-color: #f7f0b5; /* Gold background for highlights */
  }
  
  .highlights-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .highlight-card {
    background: #1c5f01; /* Main theme color */
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .highlight-card h3 {
    color: #f7f0b5; /* Gold for titles */
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Pricing Section */
  .conference-pricing {
    padding: 50px 20px;
    background-color: #e8f5e9; /* Light green background */
    text-align: center;
  }
  
  .pricing-options {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 250px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pricing-card:hover {
    transform: scale(1.05);
    transition: all 0.3s ease;
  }
  
  .pricing-card h3 {
    margin-bottom: 10px;
    font-size: 1.3rem;
    color: #1c5f01;
  }

  .pricing-card span{
    color: #1c5f01;
    font-weight: bold;
  }

  .contact-link {
    color: #1c5f01; /* Main theme color */
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-link:hover {
    color: #4caf50; /* Brighter green for hover effect */
    text-decoration: underline;
  }


  
  
  /* Reviews Section */
  .customer-reviews {
    padding: 50px 20px;
    background: #f7f0b5; /* Gold background */
    text-align: center;
  }
  
  .review-slider {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  
  .review-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 300%;
  }
  
  .review-content {
    width: 100%;
    padding: 40px 20px;
    margin: 0 20px;
    background: linear-gradient(135deg, #1c5f01, #4caf50); /* Gradient with theme color */
    border-radius: 15px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  
  .review-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #f7f0b5; /* Gold */
    text-transform: uppercase;
  }
  
  .review-text {
    font-size: 1.2rem;
    font-style: italic;
    line-height: 1.5;
  }


  @media (max-width: 768px) {
    .conference-hero {
      padding: 80px 15px;
    }
  
    .hero-content h1 {
      font-size: 2rem; /* Adjust font size */
    }
  
    .hero-content p {
      font-size: 1rem; /* Reduce secondary text size */
    }
  
    .conference-layout {
      padding: 30px 15px;
    }
  
    .conference-layout h2 {
      font-size: 1.8rem;
    }
  
    .conference-pricing {
      padding: 30px 15px;
    }
  
    .pricing-card {
      width: 100%; /* Make pricing cards take full width */
      margin-bottom: 15px;
    }
  
    .highlights-grid {
      grid-template-columns: 1fr; /* Single-column layout */
    }
  
    .highlight-card {
      padding: 15px;
    }
  
    .review-content {
      padding: 20px 15px;
    }
  }
  
  /* Small devices (Phones, max-width: 480px) */
  @media (max-width: 480px) {
    .conference-hero {
      padding: 50px 10px;
    }
  
    .hero-content h1 {
      font-size: 1.8rem; /* Further reduce heading size */
    }
  
    .hero-content p {
      font-size: 0.9rem; /* Reduce paragraph size */
    }
  
    .conference-layout {
      padding: 20px 10px;
    }
  
    .layout-grid {
      grid-template-columns: 1fr; /* Single-column layout */
      gap: 10px;
    }
  
    .layout-card {
      padding: 10px;
      box-shadow: none; /* Simplify shadow for smaller screens */
    }
  
    .conference-highlights {
      padding: 20px 10px;
    }
  
    .highlights-grid {
      grid-template-columns: 1fr; /* Stack highlights vertically */
    }
  
    .highlight-card {
      padding: 10px;
    }
  
    .conference-pricing {
      padding: 20px 10px;
    }
  
    .pricing-card {
      width: 100%; /* Full width for cards */
      margin-bottom: 10px;
    }
  
    .customer-reviews {
      padding: 20px 10px;
    }
  
    .review-content {
      font-size: 0.9rem;
      line-height: 1.4;
    }
  
    .review-name {
      font-size: 1.2rem;
    }
  
    .review-text {
      font-size: 1rem;
    }

    .review-card {
      display: flex;
      transition: transform 0.5s ease-in-out;
      width: 990%; /* Adjust for the number of reviews */
    }
  }
  