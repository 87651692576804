.about-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.audio-prompt {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1000;
}

.audio-prompt h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.audio-prompt button {
    background-color: rgb(28, 95, 1);;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.audio-prompt button:hover {
    background-color: #0056b3;
}

.about-row {
    display: flex;
    align-items: center;
    gap: 20px;
}

.about-row:nth-child(even) {
    flex-direction: row-reverse;
}

.about-image img {
    width: 800px;
    height: auto;
    border-radius: 10px;
}

.about-text p {
    font-size: 2.5em;
    line-height: 1.5;
}


/* For medium devices (tablets, max-width: 768px) */
@media (max-width: 768px) {
    .about-container {
      padding: 15px;
      gap: 15px;
    }
  
    .about-row {
      flex-direction: column; /* Stack elements vertically */
      text-align: center; /* Center-align text and images */
    }
  
    .about-image img {
      width: 600px; /* Reduce image size */
    }
  
    .about-text p {
      font-size: 1.8em; /* Reduce font size */
    }
  
    .audio-prompt {
      width: 90%; /* Make the prompt fit smaller screens */
      padding: 15px;
    }
  
    .audio-prompt h3 {
      font-size: 1.2rem; /* Adjust heading size */
    }
  
    .audio-prompt button {
      padding: 8px 16px;
    }
  }
  
  /* For small devices (phones, max-width: 480px) */
  @media (max-width: 480px) {
    .about-container {
      padding: 10px;
      gap: 10px;
    }
  
    .about-row {
      flex-direction: column; /* Ensure vertical stacking */
      text-align: center;
    }
  
    .about-image img {
      width: 100%; /* Make image fully responsive */
    }
  
    .about-text p {
      font-size: 1.2em; /* Further reduce font size */
    }
  
    .audio-prompt {
      padding: 10px;
      font-size: 0.9rem; /* Adjust the overall size */
    }
  
    .audio-prompt button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  }

  @media (max-width: 680px) {
    .about-container {
      padding: 10px;
      gap: 10px;
    }
  
    .about-row {
      flex-direction: column; /* Ensure vertical stacking */
      text-align: center;
    }
  
    .about-image img {
      width: 100%; /* Make image fully responsive */
    }
  
    .about-text p {
      font-size: 1.2em; /* Further reduce font size */
    }
  
    .audio-prompt {
      padding: 10px;
      font-size: 0.9rem; /* Adjust the overall size */
    }
  
    .audio-prompt button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  }