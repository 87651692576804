/* src/App.css */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: rgb(28, 95, 1);
  color: rgb(248, 244, 0);
  padding: 1rem;
  text-align: center;
}

nav {
  margin-top: 1rem;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav li {
  margin: 0.5rem 0;
}

nav a {
  color: white;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.App-content {
  flex: 1;
  padding: 2rem;
}

.App-footer {
  background-color: rgba(28, 95, 1, 0.5); /* Slightly transparent dark background */
  color: white;
  padding: 1rem;
  text-align: center;
  margin-top: auto;
}

/* Responsive Styles */

/* Medium screens (Tablets and up) */
@media (min-width: 768px) {
  nav ul {
    flex-direction: row;
    justify-content: center;
  }

  nav li {
    margin: 0 1rem;
  }

  .App-header h1 {
    font-size: 2rem;
  }
}

/* Large screens (Desktops and up) */
@media (min-width: 1024px) {
  .App-header h1 {
    font-size: 2.5rem;
  }

  .App-content {
    padding: 3rem;
  }
}


