.gallery-container {
    position: relative;
    padding: 20px;
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card {
    position: relative;
    width: 250px;
    height: 250px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    align-items: center;
    margin: auto;
  }
  
  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-name {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 16px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .full-view {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
  }
  
  .full-view-image {
    width: 100%;
    height: 90vh;
    border-radius: 10px;
    transition: transform 0.2s ease;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 30px; /* Adjust the size of the close icon */
    border-radius: 5px;
  }

  .prev-icon,
  .next-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 40px; /* Adjust the size of the icons */
  background: rgba(0, 0, 0, 0.6);
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev-icon {
  left: 10px; /* Align to the left side */
}

.next-icon {
  right: 10px; /* Align to the right side */
}

/* Optional: Hover effect for icons */
.prev-icon:hover,
.next-icon:hover,
.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

  .filter-btn {
    padding: 8px 12px;
    margin: 5px;
    cursor: pointer;
    background-color: #f7f0b5;
    border: none;
    border-radius: 4px;
    color: #333;
    font-size: 1.2rem;
  }

  .filter-btn:hover {
    background: #32b809;
    transform: scale(1.1);
  }
  
  .filter-btn.active {
    background-color: rgb(28, 95, 1);
    color: white;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: rgb(28, 95, 1);
    color: white;
    border: none;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 16px;
    color: #333;
  }
  
  /* Medium devices (Tablets, max-width: 768px) */
@media (max-width: 768px) {
  .gallery {
    gap: 15px; /* Reduce spacing between items */
    justify-content: center; /* Center align gallery items */
  }

  .card {
    width: 200px; /* Adjust card width for smaller screens */
    height: 200px; /* Adjust card height for proportionate size */
  }

  .card-name {
    font-size: 14px; /* Adjust font size for card titles */
    padding: 8px;
  }

  .filter-btn {
    font-size: 1rem; /* Adjust button font size */
    padding: 6px 10px; /* Reduce padding */
  }

  .pagination button {
    font-size: 14px; /* Adjust font size for pagination */
    padding: 6px 12px; /* Adjust button padding */
  }
}

/* Small devices (Phones, max-width: 480px) */
@media (max-width: 480px) {
  .gallery-container {
    padding: 10px; /* Reduce container padding for phones */
  }

  .gallery {
    gap: 10px; /* Further reduce gap */
  }

  .card {
    width: 100%; /* Compact card size */
    height: 150px;
  }

  .card-name {
    font-size: 12px; /* Compact font size */
    padding: 6px;
  }

  .full-view {
    max-width: 100%;
    max-height: 80vh; /* Ensure images fit within the viewport */
  }

  .full-view-image {
    height: auto; /* Adjust height for smaller screens */
  }

  .close-btn {
    font-size: 20px; /* Adjust close button size */
    padding: 6px; /* Compact padding */
  }

  .prev-icon,
  .next-icon {
    font-size: 30px; /* Reduce arrow size */
    padding: 6px;
  }

  .filter-btn {
    font-size: 0.9rem; /* Compact button font */
    padding: 4px 8px; /* Smaller padding */
  }

  .pagination button {
    font-size: 12px; /* Adjust pagination font size */
    padding: 5px 10px; /* Compact button padding */
  }
}