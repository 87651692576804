Home Page Styling
.home-page {
    font-family: 'Arial', sans-serif;
}
  
/* Hero Section */
.home-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background: linear-gradient(
        rgba(28, 95, 1, 0.4),
        rgba(28, 95, 1, 0.4)
      ),
      url('../images/entrance.jpg')
        center/cover no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 50px;
}

.home-hero-content {
    max-width: 600px;
}

.home-hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.home-hero p {
    font-size: 1.5rem;
    margin-bottom: 30px;
}


.restaurant, .rooms, .conference, .gallery, .about-us, .jobs, .contact {
    padding: 50px;
    background-color: #fff;
    margin-bottom: 20px; /* Adjust spacing as needed */
}

.restaurant-content, .rooms-content, .conference-content, .gallery-content, .about-us-content, .jobs-content, .contact-content {
    display: flex;
    gap: 80px;
    text-align: center;
}

.text-container h2 {
    font-size: 2.5rem;
}

.text-container p {
    font-size: 1.5rem;
}


.image-container img{
    max-width: 800px;
    height: auto;
}

.home-cta-button{
    background-color: #000000;
    color: white;
    padding: 15px 30px;
    border-radius: 100px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.home-cta-button:hover {
    background-color: #145a00;
}

.home-customer-reviews {
    text-align: center;
    margin: 50px 0;
  }
  
  .home-review-slider {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  
  .home-review-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 333.33%; /* Adjust for the number of reviews */
  }
  
  .home-review-content {
    width: 100%;
    padding: 40px 20px;
    margin: 0 20px;
    background: linear-gradient(135deg, #1c5f01, #f7f0b5);
    border-radius: 15px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(20px);
    opacity: 0;
    animation: slideIn 0.8s ease-out forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .home-review-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #4e4e4e;
    text-transform: uppercase;
  }
  
  .home-review-text {
    font-size: 1.2rem;
    color: #000000;
    font-style: italic;
    line-height: 1.5;
    padding-top: 10px;
  }
  
  /* Adding a border to each review for more structure */
  .home-review-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 5px;
    background: #1c5f01;
    border-radius: 2px;
    margin-top: -25px;
  }
  
  /* Add subtle hover effect */
  .home-review-content:hover {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .social-media {
    margin-top: 20px;
    text-align: center;
  }
  
  .social-icons a {
    margin: 0 15px;
    color: #333; /* Set the color of the icons */
    transition: color 0.3s ease-in-out;
  }
  
  .social-icons a:hover {
    color: #0073e6; /* Change color on hover */
  }
  
  
  .form-status {
    margin-top: 10px;
    color: green;
  }

  .icon-image {
    filter: grayscale(100%); /* Makes the image grayscale */
  }
  
  .icon-image:hover {
    filter: grayscale(0%) sepia(100%) hue-rotate(180deg) saturate(200%); /* Adds color shift on hover */
  }

  .x-icon {
    background-color: aliceblue;
  }

  .x-icon:hover {
    background-color: #0073e6;
  }
  
  
  /* Medium devices (Tablets, max-width: 768px) */
@media (max-width: 768px) {
  .home-hero {
    height: 40vh; /* Reduce hero section height */
    padding: 30px;
    background-position: center;
  }

  .home-hero h1 {
    font-size: 2rem; /* Adjust font size */
  }

  .home-hero p {
    font-size: 1.2rem;
  }

  .restaurant-content, 
  .rooms-content, 
  .conference-content, 
  .gallery-content, 
  .about-us-content, 
  .jobs-content, 
  .contact-content {
    flex-direction: column; /* Stack content vertically */
    gap: 40px; /* Reduce gap between sections */
  }

  .image-container img {
    max-width: 100%; /* Ensure images fit smaller screens */
  }

  .text-container h2 {
    font-size: 2rem; /* Adjust heading size */
  }

  .text-container p {
    font-size: 1.2rem;
  }

  .home-cta-button {
    font-size: 1rem; /* Adjust button size */
    padding: 12px 20px;
  }

  .home-review-content {
    padding: 30px 15px; /* Compact padding for reviews */
    margin: 10px;
  }

  .home-review-text {
    font-size: 1rem; /* Adjust text size for reviews */
  }
}

/* Small devices (Phones, max-width: 480px) */
@media (max-width: 480px) {
  .home-hero {
    height: 30vh; /* Further reduce hero section height */
    padding: 20px;
  }

  .home-hero h1 {
    font-size: 1.5rem; /* Compact heading */
  }

  .home-hero p {
    font-size: 1rem; /* Compact subtext */
  }

  .restaurant-content, 
  .rooms-content, 
  .conference-content, 
  .gallery-content, 
  .about-us-content, 
  .jobs-content, 
  .contact-content {
    gap: 20px; /* Compact section spacing */
  }

  .text-container h2 {
    font-size: 1.5rem; /* Adjust heading size */
  }

  .text-container p {
    font-size: 1rem;
  }

  .home-cta-button {
    font-size: 0.9rem; /* Smaller button */
    padding: 10px 15px;
  }

  .home-review-slider {
    margin: 20px 0;
  }

  .home-review-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 990%; /* Adjust for the number of reviews */
  }

  .home-review-content {
    padding: 20px; /* Further compact review cards */
    font-size: 0.9rem; /* Adjust text size */
  }

  .social-icons a {
    margin: 0 10px; /* Adjust icon spacing */
  }

  .social-icons a:hover {
    color: #28a745; /* Refined hover color */
  }
}