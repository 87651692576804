/* General Page Styling */
.rooms-page {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f7f6;
    color: #333;
  }
  
  .room-hero {
    background: linear-gradient(
        rgba(28, 95, 1, 0.7),
        rgba(28, 95, 1, 0.7)
      ),
      url('../images/room.jpg')
        center/cover no-repeat;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
  }
  
  .room-hero h1 {
    font-size: 2.8rem;
    color: #f7f0b5; /* Light gold for a premium feel */
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
  .room-hero p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #e0d3a0; /* Softer gold for secondary text */
  }
  
  .room-types {
    padding: 40px 20px;
    background-color: #ecf0f1;
  }

  .room-types h2{
    text-align: center;
  }

  .perks-section h2{
    text-align: center;
  }
  
  
  .room-types .room-selector {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .room-btn {
    padding: 8px 12px;
    margin: 5px;
    cursor: pointer;
    background-color: #f7f0b5;
    border: none;
    border-radius: 4px;
    color: #333;
    font-size: 1.2rem;
  }

  .room-btn:hover {
    background: #32b809;
    transform: scale(1.1);
  }
  
  .room-btn.active {
    background-color: #1c5f01;
    color: white;
  }
  
  .room-display {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
  }
  
  .room-details {
    max-width: 450px;
    text-align: center;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .room-price {
    font-size: 1.3rem;
    color: #1c5f01;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .room-image-container {
    max-width: 600px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .room-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s;
  }
  
  .room-image:hover {
    transform: scale(1.1);
  }
  
  .perks-section {
    background-color: #1c5f01;
    color: white;
    padding: 50px 20px;
    margin-top: 40px;
  }
  
  .perks-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 1.3rem;
  }
  
  .perk-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    background-color: white;
    color: #1c5f01;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  }
  
  .perk-item .perk-icon {
    font-size: 2.5rem;
    flex-shrink: 0;
  }
  
  .perk-content {
    max-width: 500px;
  }
  
  .perk-label {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .perk-description {
    font-size: 1.1rem;
  }

  .room-book-now-section {
    background-color: #fff;
    color: white;
    padding: 50px 20px;
    margin-top: 40px;
  }

  .room-book-now{
    padding: 20px;
    background-color: #ffffff;
    text-align: center;
  }

  .room-book-now button {
    padding: 10px;
    border-radius: 5px;
    background-color: #1c5f01;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
  }

  .room-book-now a {
    color: #ffffff;
    text-decoration: none;
  }

  .room-book-now button:hover {
    background: #32b809;
    transform: scale(1.1);
  }
  
  /* Reviews Section */
  .customer-reviews {
    text-align: center;
    margin: 50px 0;
  }
  
  .review-slider {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  
  .review-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 300%; /* Each review takes up 100% width, so 3 reviews = 300% */
  }
  
  .review-content {
    width: 100%;
    padding: 40px 20px;
    margin: 0 20px;
    background: linear-gradient(135deg, #1c5f01, #f7f0b5);
    border-radius: 15px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(20px);
    opacity: 0;
    animation: slideIn 0.8s ease-out forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .review-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #4e4e4e;
    text-transform: uppercase;
  }
  
  .review-text {
    font-size: 1.2rem;
    color: #000;
    font-style: italic;
    line-height: 1.5;
    padding-top: 10px;
  }
  
  /* Adding a border to each review for more structure */
  .review-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 5px;
    background: #1c5f01;
    border-radius: 2px;
    margin-top: -25px;
  }
  
  /* Add subtle hover effect */
  .review-content:hover {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  /* Medium devices (Tablets, max-width: 768px) */
@media (max-width: 768px) {
  .room-hero {
    padding: 80px 15px; /* Adjusted padding for tablets */
  }

  .room-hero h1 {
    font-size: 2.2rem; /* Slightly smaller heading */
  }

  .room-hero p {
    font-size: 1rem; /* Smaller text for the description */
  }

  .room-types .room-selector {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
  }

  .room-display {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .room-details {
    width: 100%;
    max-width: 400px; /* Reduced width for better layout on small screens */
  }

  .room-price {
    font-size: 1.1rem;
  }

  .room-image-container {
    max-width: 100%;
  }

  .room-image {
    height: auto;
  }

  .perks-section {
    padding: 40px 15px; /* Adjusted padding for smaller screens */
  }

  .perk-item {
    flex-direction: column; /* Stack perks vertically */
    text-align: center;
    gap: 15px;
  }

  .perk-icon {
    font-size: 2rem;
  }

  .room-book-now button {
    font-size: 1.1rem;
    padding: 8px 12px; /* Reduced padding */
  }

  .customer-reviews .review-content {
    padding: 20px 15px;
    font-size: 1rem;
  }

  .review-slider {
    width: 100%;
  }
}

/* Small devices (Phones, max-width: 480px) */
@media (max-width: 480px) {
  .room-hero {
    padding: 60px 10px; /* Compact padding for phones */
  }

  .room-hero h1 {
    font-size: 1.8rem; /* Smaller heading for mobile */
  }

  .room-hero p {
    font-size: 0.9rem; /* Smaller text for the description */
  }

  .room-types .room-selector {
    flex-direction: column;
    gap: 15px;
  }

  .room-display {
    flex-direction: column;
    gap: 15px;
  }

  .room-details {
    width: 100%;
    padding: 15px;
  }

  .room-price {
    font-size: 1rem;
  }

  .room-image-container {
    max-width: 100%;
  }

  .room-image {
    height: 250px; /* Adjust for smaller screens */
    object-fit: cover;
  }

  .perks-section {
    padding: 30px 10px;
  }

  .perk-item {
    padding: 15px;
    font-size: 1.1rem;
  }

  .perk-icon {
    font-size: 2rem;
  }

  .room-book-now button {
    font-size: 1rem;
    padding: 8px 10px;
  }

  .customer-reviews .review-content {
    padding: 15px;
    font-size: 0.9rem;
  }

  .review-slider {
    width: 100%;
  }
}