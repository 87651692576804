.restaurant-page {
  font-family: Arial, sans-serif;
  color: #333;
}

.restaurant-hero {
  background: linear-gradient(
        rgba(28, 95, 1, 0.7),
        rgba(28, 95, 1, 0.7)
      ),
      url('../images/restaurant.jpg')
        center/cover no-repeat;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
}

.hero-content h1 {
  font-size: 2.8rem;
  color: #f7f0b5; /* Light gold for a premium feel */
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: center;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #e0d3a0; /* Softer gold for secondary text */
  text-align: center;
}

.restaurant-photo-gallery {
    margin: 2px;
    text-align: center;
  }
  
  .restaurant-gallery {
    position: relative;
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .restaurant-gallery-image {
    width: 100%;
    height: 400px;
    object-fit: cover; /* Ensures the image maintains its aspect ratio */
    border-radius: 8px;
  }


/* Menu Section */
.menu-section {
  text-align: center;
  margin: 50px 0;
  padding: 20px;
  background: linear-gradient(to bottom, #fff, #f8f9fa);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}


.menu-filters {
  display: flex;
  justify-content: center;
  align-items: center; /* Align buttons vertically */
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
  margin-bottom: 20px;
  gap: 15px; /* Increased spacing for better clarity */
  padding: 10px; /* Added padding for better overall spacing */
}

/* Restaurant Buttons */
.restaurant-btn {
  padding: 10px 16px; /* Adjusted padding for a more balanced look */
  margin: 8px; /* Added more space around each button */
  cursor: pointer;
  background-color: #32b809;
  border: none;
  border-radius: 6px; /* Slightly larger border radius for a modern look */
  color: white; /* Changed text color for better contrast */
  font-size: 1rem; /* Slightly reduced font size for better proportion */
  font-weight: bold; /* Added bold text for emphasis */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.restaurant-btn:hover {
  background: #ff856f;
  transform: scale(1.1);
}

.restaurant-btn.active {
  background-color: rgb(28, 95, 1);
  color: white;
}

.menu-items {
  display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.menu-item {
  position: relative;
  width: 30%;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  margin: auto;
  background: linear-gradient(135deg, #f7b7b7, #f7f0b5);
}

.menu-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.menu-item-name {
  font-size: 1.25rem;
  color: #333;
  font-weight: 600;
  color: #1c5f01;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-right: 0;
}

.menu-item-price {
  font-size: 1.2rem;
  color: #444;
  padding: 15px;
  text-align: center;
}

.restaurant-order-now-section {
  background-color: #fff;
  color: white;
  padding: 50px 20px;
  margin-top: 40px;
}

.restaurant-order-now{
  padding: 20px;
  background-color: #ffffff;
  text-align: center;
}

.restaurant-order-now button {
  padding: 10px;
  border-radius: 5px;
  background-color: #1c5f01;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}

.restaurant-order-now a {
  color: #ffffff;
  text-decoration: none;
}

.restaurant-order-now button:hover {
  background: #32b809;
  transform: scale(1.1);
}


.customer-reviews {
    text-align: center;
    margin: 50px 0;
  }
  
  .review-slider {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  
  .review-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 333.33%; /* Adjust for the number of reviews */
  }
  
  .review-content {
    width: 100%;
    padding: 40px 20px;
    margin: 0 20px;
    background: linear-gradient(135deg, #1c5f01, #f7f0b5);
    border-radius: 15px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(20px);
    opacity: 0;
    animation: slideIn 0.8s ease-out forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .review-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #4e4e4e;
    text-transform: uppercase;
  }
  
  .review-text {
    font-size: 1.2rem;
    color: #000000;
    font-style: italic;
    line-height: 1.5;
    padding-top: 10px;
  }
  
  /* Adding a border to each review for more structure */
  .review-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 5px;
    background: #1c5f01;
    border-radius: 2px;
    margin-top: -25px;
  }
  
  /* Add subtle hover effect */
  .review-content:hover {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  /* Medium devices (Tablets, max-width: 768px) */
@media (max-width: 768px) {
  .restaurant-hero {
    padding: 60px 15px; /* Compact padding for tablets */
    background-size: cover;
  }

  .hero-content h1 {
    font-size: 2.2rem; /* Reduce heading size */
  }

  .hero-content p {
    font-size: 1rem; /* Compact secondary text */
  }

  .menu-items {
    flex-wrap: wrap;
    gap: 15px; /* Reduce gap for compact layouts */
  }

  .menu-item {
    width: 45%; /* Adjust card size for two-per-row */
  }

  .menu-item-name {
    font-size: 1rem; /* Slightly smaller text */
  }

  .menu-filters {
    gap: 10px; /* Reduce gap for smaller screens */
    padding: 5px; /* Compact padding */
  }

  .restaurant-btn {
    padding: 8px 12px; /* Smaller buttons for compact displays */
    font-size: 0.9rem; /* Reduce font size for smaller screens */
  }

  .photo-gallery {
    padding: 20px;
  }

  .gallery-image {
    height: 250px; /* Adjust image size for smaller screens */
  }
}

/* Small devices (Phones, max-width: 480px) */
@media (max-width: 480px) {
  .restaurant-hero {
    padding: 40px 10px; /* Compact padding for phones */
  }

  .hero-content h1 {
    font-size: 1.8rem; /* Smaller hero heading */
  }

  .hero-content p {
    font-size: 0.9rem; /* Compact subtext */
  }
  
  .menu-filters {
    gap: 8px; /* Reduce gap further for compact layouts */
  }

  .restaurant-btn {
    width: 50%; /* Full-width buttons for better usability */
    text-align: center;
  }

  .menu-items {
    gap: 10px; /* Compact gap for small devices */
  }

  .menu-item {
    width: 100%; /* Stack cards vertically */
  }

  .menu-item-name {
    font-size: 0.9rem; /* Adjust font size */
  }

  .restaurant-gallery-image {
    height: 150px; /* Adjust for smaller screens */
  }

  .restaurant-order-now button {
    font-size: 1rem; /* Smaller button text */
    padding: 8px 15px;
  }

  .customer-reviews .review-content {
    padding: 20px 10px;
    font-size: 1rem; /* Adjust font size */
  }
  .review-card {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 990%; /* Adjust for the number of reviews */
  }
}